import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';

const TradingViewChart = ({ pair }) => {
  return (
    <div className="tradingview-chart-container">
      <TradingViewWidget
        symbol={pair} // Dynamically update the trading pair
        interval="15" // 15-minute interval
        theme="dark" // Use a dark theme
        locale="en" // English language
        autosize // Auto-resize to the container
        hide_side_toolbar={false} // Keep side toolbar visible
        enable_publishing={false} // Disable publish idea button
        allow_symbol_change={true} // Allow switching symbols
      />
    </div>
  );
};

export default TradingViewChart;
