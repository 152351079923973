import React, { useState } from 'react';
import './TokenSelector.css';

const tokens = [
  { symbol: 'SOL', name: 'Solana', balance: '1.005', price: '$228.91' },
  { symbol: 'ETH', name: 'Ethereum', balance: '0.876', price: '$1,800.33' },
  { symbol: 'BTC', name: 'Bitcoin', balance: '0.025', price: '$29,000.12' },
  { symbol: 'USDC', name: 'USD Coin', balance: '500', price: '$500.00' },
  { symbol: 'USDT', name: 'Tether', balance: '200', price: '$200.00' },
];

const TokenSelector = ({ isOpen, onClose, onSelect }) => {
  return (
    <div className={`token-selector ${isOpen ? 'open' : ''}`}>
      <div className="token-selector-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h3>Select a Token</h3>
        <div className="token-list">
          {tokens.map((token) => (
            <div
              className="token-item"
              key={token.symbol}
              onClick={() => onSelect(token.symbol)}
            >
              <div className="token-symbol">{token.symbol}</div>
              <div className="token-details">
                <span className="token-name">{token.name}</span>
                <span className="token-balance">{token.balance} {token.symbol}</span>
              </div>
              <div className="token-price">{token.price}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TokenSelector;
