import React from 'react';
import './KnowledgeCenter.css';

const KnowledgeCenter = () => {
  return (
    <div className="knowledge-center">
      <h1>Knowledge Center</h1>
      <p>📚 Coming Soon 📚</p>
    </div>
  );
};

export default KnowledgeCenter;
