import React from 'react';
import './RiskTools.css';

const RiskTools = () => {
  return (
    <div className="risk-tools">
      <h1>Risk Tools</h1>
      <p>🚀 Coming Soon 🚀</p>
    </div>
  );
};

export default RiskTools;
