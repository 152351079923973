import React from 'react';
import './TradeHistoryTable.css';

const TradeHistoryTable = () => {
  const trades = [
    { pair: 'ETH/USDT', entry: 1750, exit: 1800, size: 1.5, pnl: 75 },
    { pair: 'BTC/USDT', entry: 25000, exit: 24000, size: 0.2, pnl: -200 },
  ];

  return (
    <div className="trade-history">
      <h2>Trade History</h2>
      <table>
        <thead>
          <tr>
            <th>Pair</th>
            <th>Entry</th>
            <th>Exit</th>
            <th>Size</th>
            <th>P&L</th>
          </tr>
        </thead>
        <tbody>
          {trades.map((trade, index) => (
            <tr key={index}>
              <td>{trade.pair}</td>
              <td>${trade.entry}</td>
              <td>${trade.exit}</td>
              <td>{trade.size}</td>
              <td style={{ color: trade.pnl >= 0 ? 'green' : 'red' }}>
                ${trade.pnl}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TradeHistoryTable;
