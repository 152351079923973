import React, { useState } from 'react';
import './OpenPositionsTable.css';

const OpenPositionsTable = () => {
  const positions = [
    {
      pair: 'ETH/USDT',
      entryPrice: 1750,
      currentPrice: 1800,
      size: 1.5,
      pnl: 75,
      leverage: 10,
    },
    {
      pair: 'BTC/USDT',
      entryPrice: 25000,
      currentPrice: 24000,
      size: 0.2,
      pnl: -200,
      leverage: 5,
    },
    {
      pair: 'SOL/USDT',
      entryPrice: 20,
      currentPrice: 25,
      size: 10,
      pnl: 50,
      leverage: 2,
    },
  ];

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll((prev) => !prev);
  };

  return (
    <div className="open-positions">
      {/* Desktop Table */}
      <table>
        <thead>
          <tr>
            <th>Pair</th>
            <th>Entry Price</th>
            <th>Current Price</th>
            <th>Position Size</th>
            <th>P&L</th>
            <th>Leverage</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {positions.map((position, index) => (
            <tr key={index}>
              <td>{position.pair}</td>
              <td>${position.entryPrice}</td>
              <td>${position.currentPrice}</td>
              <td>{position.size}</td>
              <td style={{ color: position.pnl >= 0 ? 'green' : 'red' }}>
                ${position.pnl}
              </td>
              <td>{position.leverage}x</td>
              <td>
                <button>Close</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Mobile Cards */}
      <div className="mobile-cards">
        {positions
          .slice(0, showAll ? positions.length : 1) // Show all or the first card
          .map((position, index) => (
            <div className="position-card" key={index}>
              <div>
                <label>Pair:</label>
                <span>{position.pair}</span>
              </div>
              <div>
                <label>Entry Price:</label>
                <span>${position.entryPrice}</span>
              </div>
              <div>
                <label>Current Price:</label>
                <span>${position.currentPrice}</span>
              </div>
              <div>
                <label>Position Size:</label>
                <span>{position.size}</span>
              </div>
              <div>
                <label>P&L:</label>
                <span style={{ color: position.pnl >= 0 ? 'green' : 'red' }}>
                  ${position.pnl}
                </span>
              </div>
              <div>
                <label>Leverage:</label>
                <span>{position.leverage}x</span>
              </div>
              <button>Close</button>
            </div>
          ))}
        {/* Show More Button */}
        {positions.length > 1 && (
          <button className="show-more-btn" onClick={handleShowMore}>
            {showAll ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    </div>
  );
};

export default OpenPositionsTable;
