import React, { useState } from 'react';
import './MarketAnalysis.css';
import TradingViewChart from '../components/TradingViewChart';
import TrendingPairs from '../components/TrendingPairs';
import MarketOverview from '../components/MarketOverview';

const MarketAnalysis = () => {
  const [selectedPair, setSelectedPair] = useState('BTC/USDT'); // Default pair

  return (
    <div className="market-analysis">
      <h1>Market Analysis</h1>
      <div className="market-overview">
        <MarketOverview />
      </div>
      <div className="main-content">
        <div className="chart-section">
          <TradingViewChart pair={selectedPair} />
        </div>
        <div className="trending-pairs">
          <TrendingPairs onSelectPair={setSelectedPair} />
        </div>
      </div>
      <div className="volume-heatmap">
     
      </div>
    </div>
  );
};

export default MarketAnalysis;
