import React, { useState } from 'react';
import './CreateNewOrderForm.css';

const CreateNewOrderForm = () => {
  const [pair, setPair] = useState('ETH/USDT');
  const [orderType, setOrderType] = useState('Market');
  const [size, setSize] = useState(0.5);
  const [leverage, setLeverage] = useState(5);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ pair, orderType, size, leverage });
    alert('Order submitted successfully!');
  };

  return (
    <div className="new-order">
      <h2>Create New Order</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Pair</label>
          <select value={pair} onChange={(e) => setPair(e.target.value)}>
            <option value="ETH/USDT">ETH/USDT</option>
            <option value="BTC/USDT">BTC/USDT</option>
            <option value="SOL/USDT">SOL/USDT</option>
          </select>
        </div>

        <div className="form-group">
          <label>Order Type</label>
          <select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
            <option value="Market">Market</option>
            <option value="Limit">Limit</option>
            <option value="Stop-Loss">Stop-Loss</option>
          </select>
        </div>

        <div className="form-group slider-group">
          <label>Size</label>
          <div className="slider-container">
            <input
              type="range"
              min="0.1"
              max="10"
              step="0.1"
              value={size}
              onChange={(e) => setSize(Number(e.target.value))}
            />
            <input
              type="number"
              value={size}
              onChange={(e) => setSize(Number(e.target.value))}
              className="slider-value"
            />
          </div>
        </div>

        <div className="form-group slider-group">
          <label>Leverage</label>
          <div className="slider-container">
            <input
              type="range"
              min="1"
              max="100"
              step="1"
              value={leverage}
              onChange={(e) => setLeverage(Number(e.target.value))}
            />
            <input
              type="number"
              value={leverage}
              onChange={(e) => setLeverage(Number(e.target.value))}
              className="slider-value"
            />
          </div>
        </div>

        <button type="submit" className="submit-btn">Place Order</button>
      </form>
    </div>
  );
};

export default CreateNewOrderForm;
