import React, { useState } from 'react';
import './Dashboard.css';
import TradingViewChart from './TradingViewChart';
import TokenSelector from './TokenSelector';

function Dashboard() {
  const [sellToken, setSellToken] = useState('SOL');
  const [buyToken, setBuyToken] = useState('ETH');
  const [amount, setAmount] = useState('');
  const [pair, setPair] = useState('BINANCE:SOLETH'); // Default chart pair
  const [isTokenSelectorOpen, setTokenSelectorOpen] = useState(false);
  const [isSelectingSell, setIsSelectingSell] = useState(true);

  const handleTokenSelect = (symbol) => {
    if (isSelectingSell) {
      setSellToken(symbol);
      setPair(`BINANCE:${symbol}${buyToken}`);
    } else {
      setBuyToken(symbol);
      setPair(`BINANCE:${sellToken}${symbol}`);
    }
    setTokenSelectorOpen(false);
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Welcome to LeverEdge AI</h1>
        <p>Smart and Flexible Solutions for Leverage Trading</p>
      </div>

      <div className="dashboard-content">
        <TradingViewChart pair={pair} />

        <div className="swap-card">
          <div className="swap-section">
            <label>You're Selling</label>
            <div className="swap-input-group">
              <button
                onClick={() => {
                  setIsSelectingSell(true);
                  setTokenSelectorOpen(true);
                }}
              >
                {sellToken}
              </button>
              <input
                type="number"
                placeholder="0.00"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <div className="quick-buttons">
                <button>HALF</button>
                <button>MAX</button>
              </div>
            </div>
          </div>

          <div className="swap-section">
            <label>You're Buying</label>
            <div className="swap-input-group">
              <button
                onClick={() => {
                  setIsSelectingSell(false);
                  setTokenSelectorOpen(true);
                }}
              >
                {buyToken}
              </button>
              <input type="text" placeholder="Calculated Amount" readOnly />
            </div>
          </div>

          <button className="swap-button">Swap</button>
        </div>
      </div>

      <TokenSelector
        isOpen={isTokenSelectorOpen}
        onClose={() => setTokenSelectorOpen(false)}
        onSelect={handleTokenSelect}
      />
    </div>
  );
}

export default Dashboard;
