import React, { useState } from 'react';
import './RiskManagementSection.css';

const RiskManagementSection = () => {
  const [stopLoss, setStopLoss] = useState(2);
  const [takeProfit, setTakeProfit] = useState(10);
  const [riskPerTrade, setRiskPerTrade] = useState(1);
  const [maxDrawdown, setMaxDrawdown] = useState(20);

  const handleSave = () => {
    alert(`
      Stop Loss: ${stopLoss}% 
      Take Profit: ${takeProfit}% 
      Risk per Trade: ${riskPerTrade}% 
      Maximum Drawdown: ${maxDrawdown}%
    `);
  };

  return (
    <div className="risk-management">
      <h2>Risk Management</h2>

      <div className="slider-section">
        <label>Stop Loss (%)</label>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="100"
            value={stopLoss}
            onChange={(e) => setStopLoss(e.target.value)}
          />
          <input
            type="number"
            value={stopLoss}
            onChange={(e) => setStopLoss(e.target.value)}
          />
        </div>
      </div>

      <div className="slider-section">
        <label>Take Profit (%)</label>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="100"
            value={takeProfit}
            onChange={(e) => setTakeProfit(e.target.value)}
          />
          <input
            type="number"
            value={takeProfit}
            onChange={(e) => setTakeProfit(e.target.value)}
          />
        </div>
      </div>

      <div className="slider-section">
        <label>Risk per Trade (%)</label>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="10"
            step="0.1"
            value={riskPerTrade}
            onChange={(e) => setRiskPerTrade(e.target.value)}
          />
          <input
            type="number"
            step="0.1"
            value={riskPerTrade}
            onChange={(e) => setRiskPerTrade(e.target.value)}
          />
        </div>
      </div>

      <div className="slider-section">
        <label>Maximum Drawdown (%)</label>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="50"
            step="1"
            value={maxDrawdown}
            onChange={(e) => setMaxDrawdown(e.target.value)}
          />
          <input
            type="number"
            step="1"
            value={maxDrawdown}
            onChange={(e) => setMaxDrawdown(e.target.value)}
          />
        </div>
      </div>

      <button onClick={handleSave} className="save-btn">
        Save
      </button>
    </div>
  );
};

export default RiskManagementSection;
