import React from "react";
import "./PositionManagement.css";
import OpenPositionsTable from "../components/OpenPositionsTable";
import CreateNewOrderForm from "../components/CreateNewOrderForm";
import RiskManagementSection from "../components/RiskManagementSection";
import TradeHistoryTable from "../components/TradeHistoryTable";

const PositionManagement = () => {
  return (
    <div className="position-management">
      <h1>Position Management</h1>
      <div className="position-content">
        {/* Row 1: Open Positions and Trade History */}
        <div className="row">
          <div className="column">
            <OpenPositionsTable />
          </div>
          <div className="column">
            <TradeHistoryTable />
          </div>
        </div>

        {/* Row 2: Risk Management and Create New Order */}
        <div className="row">
          <div className="column">
            <RiskManagementSection />
          </div>
          <div className="column">
            <CreateNewOrderForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionManagement;
