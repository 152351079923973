import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';
import './TradingViewChart.css';

const TradingViewChart = ({ symbol = 'BTCUSDT', interval = '15', theme = 'dark' }) => {
  return (
    <div className="tradingview-chart">
      <TradingViewWidget
        symbol={`BINANCE:${symbol}`}
        interval={interval} // Default interval
        theme={theme} // Dark theme for consistency
        locale="en"
        autosize
        hide_side_toolbar={false} // Show the side toolbar
        enable_publishing={false} // Disable publishing ideas
        allow_symbol_change={true} // Allow changing symbols in the widget
      />
    </div>
  );
};

export default TradingViewChart;
