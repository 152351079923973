import React from 'react';
import './TrendingPairs.css';

const TrendingPairs = ({ onSelectPair }) => {
  const pairs = [
    { pair: 'BTC/USDT', price: 50000, change: 2.5 },
    { pair: 'ETH/USDT', price: 4000, change: -1.2 },
    { pair: 'SOL/USDT', price: 200, change: 5.1 },
    { pair: 'ADA/USDT', price: 1.5, change: 3.7 },
  ];

  return (
    <div className="trending-pairs">
      <h2>Trending Pairs</h2>
      <ul>
        {pairs.map((item, index) => (
          <li key={index} onClick={() => onSelectPair(item.pair)}>
            <span>{item.pair}</span>
            <span>${item.price.toFixed(2)}</span>
            <span
              style={{ color: item.change >= 0 ? 'green' : 'red' }}
            >
              {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TrendingPairs;
