import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Dashboard from './Dashboard';
import PositionManagement from './pages/PositionManagement';
import MarketAnalysis from './pages/MarketAnalysis';
import RiskTools from './pages/RiskTools';
import KnowledgeCenter from './pages/KnowledgeCenter';
import detectEthereumProvider from '@metamask/detect-provider';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [walletInfo, setWalletInfo] = useState(null);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const connectWallet = async () => {
    try {
      const provider = await detectEthereumProvider();
      if (provider) {
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        setWalletInfo({ address: accounts[0] });
      } else {
        alert('MetaMask is not installed! Please install MetaMask to connect your wallet.');
      }
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const disconnectWallet = () => {
    setWalletInfo(null);
    alert('Wallet disconnected');
  };

  return (
    <Router>
      <div className="app">
        <header className="header">
          <div className="logo">
            <img src="logg.jpg" alt="Logo" className="logoimg" />
          </div>
          <nav className={`nav ${menuOpen ? 'nav-open' : ''}`}>
            <Link to="/" className="nav-item" onClick={() => setMenuOpen(false)}>
              Dashboard
            </Link>
            <Link
              to="/position-management"
              className="nav-item"
              onClick={() => setMenuOpen(false)}
            >
              Position Management
            </Link>
            <Link
              to="/market-analysis"
              className="nav-item"
              onClick={() => setMenuOpen(false)}
            >
              Market Analysis
            </Link>
            <Link to="/risk-tools" className="nav-item" onClick={() => setMenuOpen(false)}>
              Risk Tools
            </Link>
            <Link
              to="/knowledge-center"
              className="nav-item"
              onClick={() => setMenuOpen(false)}
            >
              Knowledge Center
            </Link>
          </nav>
          <div className="header-right">
            {walletInfo ? (
              <>
                <span className="wallet-info">
                  Wallet: {walletInfo.address.slice(0, 6)}...{walletInfo.address.slice(-4)}
                </span>
                <button className="wallet-button" onClick={disconnectWallet}>
                  Disconnect
                </button>
              </>
            ) : (
              <button className="wallet-button" onClick={connectWallet}>
                Connect Wallet
              </button>
            )}
            <button className="hamburger" onClick={toggleMenu}>
              <i className={`fas ${menuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
          </div>
        </header>

        <main className="main">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/position-management" element={<PositionManagement />} />
            <Route path="/market-analysis" element={<MarketAnalysis />} />
            <Route path="/risk-tools" element={<RiskTools />} />
            <Route path="/knowledge-center" element={<KnowledgeCenter />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
