import React from 'react';
import './MarketOverview.css';

const MarketOverview = () => {
  return (
    <div className="market-overview">
      <div>
        <label>Total Market Cap:</label>
        <span>$2.3T</span>
      </div>
      <div>
        <label>24h Volume:</label>
        <span>$300B</span>
      </div>
      <div>
        <label>BTC Dominance:</label>
        <span>42%</span>
      </div>
      <div>
        <label>ETH Dominance:</label>
        <span>18%</span>
      </div>
    </div>
  );
};

export default MarketOverview;
